/* eslint-disable react-hooks/exhaustive-deps */
// imports react and next
import React, { useState, useEffect } from "react";
import Head from "next/head";
import { renderMetaTags } from "react-datocms";
import dynamic from "next/dynamic";
import { useSelector, connect } from "react-redux";
import { SwitchTransition, Transition } from "react-transition-group";
import { useRouter } from "next/router";
import { gsap } from "gsap";

import { NEWS_POPUP_QUERY } from "@/graphql/settingsQuery";

import { fetchCMSDataQuery } from "@/services/requestService";

// imports hooks
import { useEffectOnce } from "@/hooks/useEffectCustom";

// Import required components
const Header = dynamic(() => import("@/common/Header"));
const Footer = dynamic(() => import("@/common/Footer"));
const NewsPopup = dynamic(() => import("@/components/NewsPopup"));

const TIMEOUT = 250;
const gsapDuration = TIMEOUT / 1000;

const Master = (props) => {
  const { labelsConfigData } = props;
  const router = useRouter();

  const { selectedLang } = useSelector((state) => {
    return {
      labelsConfigData: state?.labelsConfig,
      selectedLang: state?.labelsConfig?.selectedLang,
    };
  });

  const [labelsList, setLabelsList] = useState({});
  const [siteConfig, setSiteConfig] = useState({});
  const [settingsData, setSettingData] = useState();
  // const [showData, setShowData] = useState(false);
  const [showNewsPopup, setShowNewsPopup] = useState(false);

  useEffectOnce(async () => {
    const response = await fetchCMSDataQuery(NEWS_POPUP_QUERY);

    const isPopupShown = response?.newsPopup?.shown;

    setShowNewsPopup(isPopupShown);
  });

  useEffect(() => {
    setLabelsList(labelsConfigData?.labelsAndConfigData?.buttonsAndLabelsPage);
    setSiteConfig(labelsConfigData?.labelsAndConfigData?.siteConfig);
    setSettingData(labelsConfigData?.labelsAndConfigData?._site);
  }, [labelsConfigData, selectedLang]);

  // useEffect(() => {
  //   if (settingsData) {
  //     setTimeout(() => {
  //       setShowData(true);
  //     }, 500);
  //   }
  // }, [settingsData]);

  return (
    <>
      {settingsData && <Head>{renderMetaTags(settingsData?.favicon)}</Head>}

      {!labelsConfigData?.isLoading && (
        <Header
          labelsList={labelsList}
          siteConfig={siteConfig}
          settingsData={settingsData}
          menuList={labelsConfigData?.labelsAndConfigData}
          preview={props?.children?.props?.preview || false}
        />
      )}

      <SwitchTransition>
        <Transition
          key={router.pathname}
          timeout={TIMEOUT}
          onEnter={(node) => {
            gsap.set(node, { autoAlpha: 0 });
            gsap.to(node, {
              autoAlpha: 1,
              duration: gsapDuration * 3,
            });
          }}
          onExit={(node) => {
            gsap.to(node, { autoAlpha: 0, duration: gsapDuration });
          }}
        >
          <main className="main relative">{props?.children}</main>
        </Transition>
      </SwitchTransition>

      <Footer
        labelsList={labelsList}
        siteConfig={siteConfig}
        menuList={labelsConfigData?.labelsAndConfigData}
        preview={props?.children?.props?.preview || false}
      />

      {showNewsPopup && <NewsPopup setShowNewsPopup={setShowNewsPopup} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  labelsConfigData: state.labelsConfig,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Master);

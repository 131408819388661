import {
  ERROR_FETCH_USEFUL_INFORMATION_DATA,
  START_FETCH_USEFUL_INFORMATION_DATA,
  SUCCESS_FETCH_USEFUL_INFORMATION_DATA,
} from "../actions/usefulInformationActionsTypes";

const initialState = {
  isLoading: false,
  usefulInformationData: [],
  errorFetchingUsefulInformationData: "",
};

const UsefulInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_USEFUL_INFORMATION_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_USEFUL_INFORMATION_DATA:
      return {
        ...state,
        isLoading: false,
        usefulInformationData: action.usefulInformationData,
        errorFetchingUsefulInformationData: "",
      };
    case ERROR_FETCH_USEFUL_INFORMATION_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingUsefulInformationData: action.error,
      };
    default:
      return state;
  }
};
export default UsefulInformationReducer;

import {
  START_FETCH_FREIZEIT_SUBPAGE_DATA,
  ERROR_FETCH_FREIZEIT_SUBPAGE_DATA,
  SUCCESS_FETCH_FREIZEIT_SUBPAGE_DATA,
} from "../actions/freizeitSubpageActionsTypes";

const initialState = {
  isLoading: false,
  freizeitSubpageData: [],
  errorFetchingFreizeitSubPageData: "",
};

const FreizeitSubpageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_FREIZEIT_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_FREIZEIT_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: false,
        freizeitSubpageData: action.freizeitSubpageData,
        errorFetchingFreizeitSubPageData: "",
      };
    case ERROR_FETCH_FREIZEIT_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingFreizeitSubPageData: action.error,
      };
    default:
      return state;
  }
};
export default FreizeitSubpageReducer;

import { getImageRatio } from "@/utils/functions";

import { DEFAULT_FALLBACK_LOCALE } from "@/constants/constants";

const ratioList = getImageRatio();

// GraphQL query build for Labels and Configs data
export const LABEL_AND_CONFIG_QUERY = `{
  buttonsAndLabelsPage(locale: lang)  {
    more
    bookNow
    download
    email
    close
    phone
    address
    info
    prices
    viewMap
    searchAPitch
    camper
    van
    selectAZone
    pitchesList
    smallTent
    smallTentAndACar
    tentAndACar
    caravanAndCar
    selectEquipment
    filterForPosition
    far
    near
    lakeDistance
    sanitaryDistance
    playgroundDistance
    extraPerson
    fivePeople
    fourPeople
    onePerson
    threePeople
    twoPeople
    map
    meteo
    tour
    webcam
    number
    width
    vehicle
    tv
    sewage
    perfectFor
    maxPeople
    internet
    freshWater
    electricity
    weatherTitle
    camperIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    caravanAndCarIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    noneSelected
    smallTentAndACarIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    tentAndACarIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    smallTentIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    vanIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    sunday
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    accomodationType
    arrivalDate
    capacity
    search
    allAccomodations
    and
  }

  siteConfig(locale: lang) {
    header {
      ... on HomeRecord {
        slug
        title
      }
      ... on PitchesPageRecord {
        slug
        title
      }
      ... on BungalowsPageRecord {
        slug
        title
      }
      ... on ServicesPageRecord {
        slug
        title
      }
      ... on RecreationRecord {
        slug
        title
      }
      ... on TourRecord {
        slug
        title
      }
      ... on TicinoRecord {
        slug
        title
      }
      ... on UsefulInformationRecord {
        slug
        title
      }
      ... on ContactRecord {
        slug
        title
      }
    }
    logo {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    favicon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    mapMarker {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    campingMap {
      alt
      title
      url
    }
    instagramIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    instagramLink
    facebookIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    facebookLink
    youtubeIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    youtubeLink
    cookiePolicy
    privacyPolicy
    copyright
    fax
    dogsNotAllowedIcon {
      title
      url
      jpeg: url(imgixParams: {auto: format})
      webp: url(imgixParams: {auto: format})
      blurUpThumb
      alt
      width
      height
    }
    dogsNotAllowed
    credit
    credits {
      blocks
      links
      value
    }
    webcam1
    webcam2
    webcam3
    webcam4
    bookingEngine {
      seasonStart
      seasonEnd
      minimumPersons
      minimumNights
      baseUrl
      baseOutboundLinks
    }
  }
  pitchesPage(locale: lang) {
    title
  }
  bungalowsPage(locale: lang) {
    title
  }
  tour(locale: lang) {
    gallery {
      title
      url
      jpeg: url(
        imgixParams: {auto: format, ar: "${ratioList?.homePage?.topCarousel}"}
      )
      webp: url(
        imgixParams: {auto: format, ar: "${ratioList?.homePage?.topCarousel}"}
      )
      responsiveImage(imgixParams: {fit: crop, auto: format }) {
        ...responsiveImageFragment
      }
      blurUpThumb
      alt
    }
  }
  _site(locale: lang) {
    favicon: faviconMetaTags {
      attributes
      content
      tag
    }
    globalSeo {
      titleSuffix
      twitterAccount
      siteName
      facebookPageUrl
      fallbackSeo {
        title
        twitterCard
        description
      }
    }
    faviconMetaTags {
      tag
      content
      attributes
    }
    locales
  }
  
  newsPopup(locale: lang) {
    title
    singleMedia {
      title
      url
      jpeg: url(imgixParams: {auto: format, ar: "${ratioList?.homePage?.topCarousel}"})
      webp: url(imgixParams: {auto: format, ar: "${ratioList?.homePage?.topCarousel}"})
      blurUpThumb
      alt
      responsiveImage(imgixParams: {auto: format}) {
        ...responsiveImageFragment
      }
    }
    description {
      value
      links
    }
    attachment(fallbackLocales: ${DEFAULT_FALLBACK_LOCALE}) {
      url
    }
    linkToPage {
      __typename
      ... on NewsSubpageRecord {
        slug
      }
      ... on PitchesPageRecord {
        slug
      }
      ... on BungalowsPageRecord {
        slug
      }
      ... on ServiceSubpageRecord {
        slug
      }
      ... on RecreationRecord {
        slug
      }
      ... on RecreationSubpageRecord {
        slug
      }
      ... on TicinoRecord {
        slug
      }
      ... on TicinoSubpageRecord {
        slug
      }
      ... on TourRecord {
        slug
      }
      ... on WhyCampingTamaroRecord {
        slug
      }
      ... on ContactRecord {
        slug
      }
    }
  }
}`;

export const PREVIEW_LINK_QUERY = `{
    home (locale: lang) {
      title
      slug
      noindex
      nofollow
    } 
  }`;

export const NEWS_POPUP_QUERY = `{
    newsPopup {
      shown
    }
  }`;

import {
  ERROR_FETCH_TICINO_SUBPAGE_DATA,
  START_FETCH_TICINO_SUBPAGE_DATA,
  SUCCESS_FETCH_TICINO_SUBPAGE_DATA,
} from "../actions/ticinoSubpageActionsTypes";

const initialState = {
  isLoading: false,
  ticinoSubpageData: [],
  errorFetchingTicinoSubPageData: "",
};

const TicinoSubpageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_TICINO_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_TICINO_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: false,
        ticinoSubpageData: action.ticinoSubpageData,
        errorFetchingTicinoSubPageData: "",
      };
    case ERROR_FETCH_TICINO_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingTicinoSubPageData: action.error,
      };
    default:
      return state;
  }
};
export default TicinoSubpageReducer;

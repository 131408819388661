import {
  ERROR_FETCH_BUNGALOW_PAGE_DATA,
  START_FETCH_BUNGALOW_PAGE_DATA,
  SUCCESS_FETCH_BUNGALOW_PAGE_DATA,
} from "../actions/bungalowPageActionsTypes";

const initialState = {
  isLoading: false,
  bungalowPageData: [],
  errorFetchingBungalowPageData: "",
};

const BungalowPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_BUNGALOW_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_BUNGALOW_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        bungalowPageData: action.bungalowPageData,
        errorFetchingBungalowPageData: "",
      };
    case ERROR_FETCH_BUNGALOW_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingBungalowPageData: action.error,
      };
    default:
      return state;
  }
};
export default BungalowPageReducer;

import {
  START_FETCH_LABELS_CONFIG_LIST,
  SUCCESS_FETCH_LABELS_CONFIG_LIST,
  ERROR_FETCH_LABELS_CONFIG_LIST,
  SET_SELECTED_LANG,
} from "./../actions/labelsAndConfigActionsTypes";

const initialState = {
  isLoading: false,
  labelsAndConfigData: [],
  errorFetchingLabelsAndConfigData: "",
  selectedLang: "en",
};

const LabelsAndConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_LABELS_CONFIG_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_LABELS_CONFIG_LIST:
      return {
        ...state,
        isLoading: false,
        labelsAndConfigData: action.labelsConfigData,
      };
    case ERROR_FETCH_LABELS_CONFIG_LIST:
      return {
        ...state,
        isLoading: false,
        errorFetchingLabelsAndConfigData: action.error,
      };
    case SET_SELECTED_LANG:
      return { ...state, selectedLang: action.lang };
    default:
      return state;
  }
};
export default LabelsAndConfigReducer;

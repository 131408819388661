import {
  ERROR_FETCH_SERVICE_PAGE_DATA,
  START_FETCH_SERVICE_PAGE_DATA,
  SUCCESS_FETCH_SERVICE_PAGE_DATA,
} from "../actions/servicePageActionsTypes";

const initialState = {
  isLoading: false,
  servicePageData: [],
  errorFetchingServicePageData: "",
};

const ServicePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_SERVICE_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_SERVICE_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        servicePageData: action.servicePageData,
        errorFetchingServicePageData: "",
      };
    case ERROR_FETCH_SERVICE_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingServicePageData: action.error,
      };
    default:
      return state;
  }
};
export default ServicePageReducer;

import {
  ERROR_FETCH_NEWS_SUBPAGE_DATA,
  START_FETCH_NEWS_SUBPAGE_DATA,
  SUCCESS_FETCH_NEWS_SUBPAGE_DATA,
} from "../actions/newsSubpageActionsTypes";

const initialState = {
  isLoading: false,
  newsSubpageData: [],
  errorFetchingNewsSubPageData: "",
};

const NewsSubpageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_NEWS_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_NEWS_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: false,
        newsSubpageData: action.newsSubpageData,
        errorFetchingNewsSubPageData: "",
      };
    case ERROR_FETCH_NEWS_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingNewsSubPageData: action.error,
      };
    default:
      return state;
  }
};
export default NewsSubpageReducer;

import {
  ERROR_FETCH_SERVICE_SUBPAGE_DATA,
  START_FETCH_SERVICE_SUBPAGE_DATA,
  SUCCESS_FETCH_SERVICE_SUBPAGE_DATA,
} from "../actions/serviceSubpageActionsTypes";

const initialState = {
  isLoading: false,
  serviceSubpageData: [],
  errorFetchingServiceSubPageData: "",
};

const ServiceSubpageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_SERVICE_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_SERVICE_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: false,
        serviceSubpageData: action.serviceSubpageData,
        errorFetchingServiceSubpageData: "",
      };
    case ERROR_FETCH_SERVICE_SUBPAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingServiceSubpageData: action.error,
      };
    default:
      return state;
  }
};
export default ServiceSubpageReducer;

import {
  ERROR_FETCH_FREIZEIT_PAGE_DATA,
  START_FETCH_FREIZEIT_PAGE_DATA,
  SUCCESS_FETCH_FREIZEIT_PAGE_DATA,
} from "../actions/freizeitPageActionsTypes";

const initialState = {
  isLoading: false,
  freizeitPageData: [],
  errorFetchingFreizeitPageData: "",
};

const FreizeitPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_FREIZEIT_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_FREIZEIT_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        freizeitPageData: action.ferezeitPageData,
        errorFetchingFreizeitPageData: "",
      };
    case ERROR_FETCH_FREIZEIT_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingFreizeitPageData: action.error,
      };
    default:
      return state;
  }
};
export default FreizeitPageReducer;

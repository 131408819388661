import { useEffect, useState, useRef } from "react";

export const useEffectOnce = (effect, dependency = []) => {
  const destroyFunc = useRef();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  // eslint-disable-next-line no-unused-vars
  const [val, setVal] = useState(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    (async () => {
      if (!effectCalled.current) {
        // Ensure that the effect returns a cleanup function
        const cleanup = await effect();

        if (typeof cleanup === "function") destroyFunc.current = cleanup;
        else console.error("Effect did not return a valid cleanup function");

        effectCalled.current = true;
      }
      setVal((prevVal) => prevVal + 1);
    })();

    return () => {
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
    // eslint-disable-next-line
  }, dependency);
};

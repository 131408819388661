import {
  ERROR_FETCH_TICINO_PAGE_DATA,
  START_FETCH_TICINO_PAGE_DATA,
  SUCCESS_FETCH_TICINO_PAGE_DATA,
} from "../actions/ticinoPageActionsTypes";

const initialState = {
  isLoading: false,
  ticinoPageData: [],
  errorFetchingTicinoPageData: "",
};

const TicinoPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_TICINO_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_TICINO_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        ticinoPageData: action.ticinoPageData,
        errorFetchingTicinoPageData: "",
      };
    case ERROR_FETCH_TICINO_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingTicinoPageData: action.error,
      };
    default:
      return state;
  }
};
export default TicinoPageReducer;

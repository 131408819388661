const isServer = typeof window === "undefined";

/**
 * Used for return selected language data
 * @returns
 */
const getSelectedLangData = () => {
  if (!isServer) {
    let lang = "en";
    var match = document.cookie.match(new RegExp("(^| )" + "NEXT_LOCALE" + "=([^;]+)"));
    if (match?.length > 0) {
      lang = match[2];
    } else {
      set_cookie(lang);
    }
    localStorage.setItem("browser.language", btoa(lang));
    return lang;
  } else {
    return "en";
  }
};

/**
 * Set given value in cookie
 * @param {*} value
 */
const set_cookie = (value) => {
  if (!isServer) {
    // delete_cookie();
    document.cookie = `NEXT_LOCALE=${value}; Path=/;`;
  }
};

/**
 * Delete values from cookie for given name
 */
const delete_cookie = () => {
  document.cookie = "NEXT_LOCALE=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

/**
 * Get active server URL
 * @returns
 */
const getServerURL = () => {
  let hostname = "http://localhost:3000";
  if (typeof window !== "undefined") {
    hostname = window.location.origin;
  }
  return `https://${process.env.NEXT_PUBLIC_HOSTNAME}`;
};

const getWindowWidth = () => {
  let width = 1400;
  if (typeof window !== "undefined") {
    width = window?.innerWidth;
  }
  return width;
};

/**
 * Middleware for call CMS API using next API routes
 * @param {*} query
 * @param {*} preview
 * @returns
 */
const fetcher = async (query, preview, variables = {}, isURL = false) => {
  const res = await fetch(`${!isURL ? getServerURL() : ""}/api/graphql`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ query, preview, variables }),
  });

  return res.json();
};

/**
 * This function is for check  dynamic slug and redirect on frontend
 * @returns
 */
const getPreviewRedirectURL = (slug, allSlugData) => {
  let url = "/";

  return url;
};

/**
 * Used for getting image ration and used that in graphQL query
 * @returns
 */
const getImageRatio = () => {
  const width = getWindowWidth();
  let imageRatioList = {};
  if (width >= 992) {
    imageRatioList.homePage = {
      topCarousel: "16:9",
      boxImage: "1:1",
    };
    imageRatioList.bugalowsPage = {
      typologyCarousel: "",
    };
    imageRatioList.pitchesPage = {
      pitchesCarousel: "&ar64=MTY6OQ", //16:9
    };
  } else if (width >= 576) {
    imageRatioList.homePage = {
      topCarousel: "1x1",
      boxImage: "16x9",
    };
    imageRatioList.bugalowsPage = {
      typologyCarousel: "",
    };
    imageRatioList.pitchesPage = {
      pitchesCarousel: "&ar64=MTY6OQ", //16:9
    };
  } else if (width < 576) {
    imageRatioList.homePage = {
      topCarousel: "2x3",
      boxImage: "1x1",
    };
    imageRatioList.bugalowsPage = {
      typologyCarousel: "&ar64=NDoz", //4:3
    };
    imageRatioList.pitchesPage = {
      pitchesCarousel: "&ar64=NDoz", //4:3
    };
  }
  return imageRatioList;
};

//  check if value is empty or not for all type of values
const isEmpty = (value) => {
  if (value === false) return !value;
  if (value === true) return !value;
  return (
    value === null ||
    value === "undefined" ||
    value === "null" ||
    value === undefined ||
    value === "" ||
    value.length === 0 ||
    Object.keys(value).length === 0
  );
};

const getDateFormat = (d, seperater) => {
  const date = new Date(d);
  let day;
  if (date.getDate() < 10) {
    day = `0${date.getDate()}`;
  } else {
    day = date.getDate();
  }
  let month;
  if (date?.getMonth() + 1 < 10) {
    month = `0${date?.getMonth() + 1}`;
  } else {
    month = date?.getMonth() + 1;
  }
  const updatedDate = `${date.getFullYear()}${seperater}${month}${seperater}${day}`;
  return updatedDate;
};

const getDuration = (dates) => {
  const startDate = new Date(dates?.startDate);
  const endDate = new Date(dates?.endDate);
  const diffTime = Math.abs(startDate - endDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const isDueDate = (date) => {
  const dateToVerify = new Date(date);
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  if (dateToVerify < now) {
    return true;
  } else {
    return false;
  }
};

const getYoutubeVideoId = (url) => {
  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regExp);

  return match && match[1] ? match[1] : null;
};

/* Remove trailing slash on canonical url */
const removeTrailingSlash = (url) => {
  return url.endsWith("/") ? url.slice(0, -1) : url;
};

export {
  getSelectedLangData,
  set_cookie,
  delete_cookie,
  getServerURL,
  getWindowWidth,
  fetcher,
  getPreviewRedirectURL,
  getImageRatio,
  isEmpty,
  getDateFormat,
  getDuration,
  isDueDate,
  getYoutubeVideoId,
  removeTrailingSlash,
};

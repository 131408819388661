import React, { useState, useEffect } from "react";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import { Provider /* useDispatch */ } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import store from "@/redux/store";
import { pageview } from "@/lib/gtm";
const isServer = typeof window === "undefined";
// import { log } from "next-axiom";

import { useEffectOnce } from "@/hooks/useEffectCustom";

// Import master component
import Master from "@/components/Layout/Master";

import { /* getSelectedLangData, */ getPreviewRedirectURL } from "@/utils/functions";

import { PREVIEW_LINK_QUERY } from "@/graphql/settingsQuery";

// Import style CSS
import "@/styles/tailwind.css";
// import { useEffectOnce } from "@/hooks/useEffectCustom";
// import { setSelectedLang } from "@/redux/actions/labelsAndConfigActions";
import { fetchCMSDataQuery } from "@/services/requestService";
import Head from "next/head";

function MyApp({ Component, pageProps }) {
  const [isPageLoad, setPageLoad] = useState(true);
  // const dispatch = useDispatch();

  // let lang = getSelectedLangData();
  // let hostname = "http://localhost:3000";
  // if (typeof window !== "undefined") {
  //   hostname = window.location.origin;
  // }

  // log.debug("Dummy log", { hostname: hostname });

  const { asPath, pathname, route, locale, isPreview } = useRouter();

  const notifyPageView = () => {
    pageview(pathname, locale);
  };

  //set the last selected lang to cookie and redux
  // const refreshWithLocale = () => {
  //   if (route !== "/404") {
  //     let currentLang = getSelectedLangData(locale);

  //     dispatch(setSelectedLang(currentLang));
  //     setTimeout(() => {
  //       Router.push(asPath.split("#")?.[0].toLowerCase(), asPath.split("#")?.[0].toLowerCase(), {
  //         locale: currentLang,
  //       });
  //     }, 2000);
  //   }
  // };

  // using custom useEffect
  useEffectOnce(() => {
    if (locale && !isServer) {
      // this is primarily a hack to enable page tracking on first load only
      // as router events do not track first load
      notifyPageView();
    }
  }, [locale, isServer]);

  useEffect(() => {
    Router.events.on("routeChangeComplete", pageview);
    Router.events.on("routeChangeError", pageview);
    return () => {
      Router.events.off("routeChangeComplete", pageview);
      Router.events.off("routeChangeError", pageview);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.error = (error) => {
      if (/Loading chunk [\d]+ failed/.test(error?.message)) {
        window?.location?.reload();
      }
    };
    // Used for exit preview mode when open using live URL
    const exitPreviewMode = async () => {
      if (asPath?.indexOf("preview") > -1) {
        setPageLoad(false);
        const res = await fetch("/api/exit-preview/?", {
          method: "GET",
        });
        if (res) {
          let slug = asPath?.substring(0, asPath.indexOf("?"))?.replace("/", "");
          let query = PREVIEW_LINK_QUERY?.replace(/\lang/g, locale);
          const slugResponse = await fetchCMSDataQuery(query, true);
          let path = getPreviewRedirectURL(slug, slugResponse);
          await Router.push(path.toLowerCase(), path.toLowerCase(), {
            locale: locale,
          });
          setTimeout(() => {
            setPageLoad(true);
          }, 300);
        }
      }
    };

    exitPreviewMode();
  }, [asPath, locale, pathname, route]);

  return (
    <>
      <Head>
        <title>Camping Tamaro</title>
      </Head>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${process.env.GOOGLE_TAG_MANAGER_ID}');
    `,
        }}
      />
      {isPageLoad && (
        <Provider store={store}>
          <Master>
            <Component {...pageProps} />
          </Master>
        </Provider>
      )}
    </>
  );
}

const makeStore = () => store;

const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(MyApp);

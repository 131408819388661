import { combineReducers } from "redux";

// Import all required reducer
import BungalowPageReducer from "./bungalow";
import ContactPageReducer from "./contact";
import FreizeitPageReducer from "./freizeit";
import FreizeitSubpageReducer from "./freizeitSubPage";
import HomePageReducer from "./home";
import labelsConfig from "./labelsAndConfig";
import NewsSubpageReducer from "./newsSubPage";
import PitchesPageReducer from "./pitches";
import ServicePageReducer from "./servicePage";
import ServiceSubpageReducer from "./serviceSubPage";
import TicinoPageReducer from "./ticinoPage";
import TicinoSubpageReducer from "./ticinoSubPage";
import TourPageReducer from "./tourPage";
import UsefulInformationReducer from "./usefulInformation";
import WhyCampingPageReducer from "./whyCampingTamaro";

const rootReducers = combineReducers({
  homePage: HomePageReducer,
  servicePage: ServicePageReducer,
  serviceSubPage: ServiceSubpageReducer,
  freizeitSubPage: FreizeitSubpageReducer,
  ticinoSubPage: TicinoSubpageReducer,
  contactPage: ContactPageReducer,
  pitchesPage: PitchesPageReducer,
  usefulInformationPage: UsefulInformationReducer,
  tourPage: TourPageReducer,
  bungalowPage: BungalowPageReducer,
  freizeit: FreizeitPageReducer,
  ticino: TicinoPageReducer,
  newsSubPage: NewsSubpageReducer,
  whyCampingTamaro: WhyCampingPageReducer,
  labelsConfig,
});

export default rootReducers;

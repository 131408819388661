import {
  START_FETCH_PITCHES_PAGE_DATA,
  SUCCESS_FETCH_PITCHES_PAGE_DATA,
  ERROR_FETCH_PITCHES_PAGE_DATA,
} from "./../actions/pitchesPageActionsTypes";

const initialState = {
  isLoading: false,
  pitchesPageData: [],
  errorFetchingPitchesPageData: "",
};

const PitchesPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_PITCHES_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_PITCHES_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        pitchesPageData: action.pitchesPageData,
        errorFetchingPITCHESPageData: "",
      };
    case ERROR_FETCH_PITCHES_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingPitchesPageData: action.error,
      };
    default:
      return state;
  }
};
export default PitchesPageReducer;

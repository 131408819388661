import {
  START_FETCH_WHY_CAMPING_PAGE_DATA,
  SUCCESS_FETCH_WHY_CAMPING_PAGE_DATA,
  ERROR_FETCH_WHY_CAMPING_PAGE_DATA,
} from "./../actions/whyCampingTamaroPageActionsTypes";

const initialState = {
  isLoading: false,
  whyCampingPageData: [],
  errorFetchingWhyCampingPageData: "",
};

const WhyCampingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_WHY_CAMPING_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_WHY_CAMPING_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        whyCampingPageData: action.whyCampingPageData,
        errorFetchingWhyCampingPageData: "",
      };
    case ERROR_FETCH_WHY_CAMPING_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingWhyCampingPageData: action.error,
      };
    default:
      return state;
  }
};
export default WhyCampingPageReducer;

import {
  ERROR_FETCH_TOUR_PAGE_DATA,
  START_FETCH_TOUR_PAGE_DATA,
  SUCCESS_FETCH_TOUR_PAGE_DATA,
} from "../actions/tourPageActionsTypes";

const initialState = {
  isLoading: false,
  tourPageData: [],
  errorFetchingTourPageData: "",
};

const TourPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_TOUR_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_TOUR_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        tourPageData: action.tourPageData,
        errorFetchingTourPageData: "",
      };
    case ERROR_FETCH_TOUR_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingTourPageData: action.error,
      };
    default:
      return state;
  }
};
export default TourPageReducer;
